import { useMessageContext } from "../providers/message.provider";

export default function useMessage() {
  const { messageDispatch } = useMessageContext();

  const Positions = {
    POSITION_TOP_LEFT: "tl",
    POSITION_TOP_CENTER: "tc",
    POSITION_TOP_RIGHT: "tr",
    POSITION_TOP_BOTTOM_LEFT: "bl",
    POSITION_TOP_BOTTOM_CENTER: "bc",
    POSITION_TOP_BOTTOM_RIGHT: "br",
  };

  const Types = {
    TYPE_MESSAGE: "success",
    TYPE_ERROR: "danger",
    TYPE_WARNING: "warning",
    TYPE_INFO: "info",
  };

  const Icons = {
    DEFAULT_WARNING_ICON: "fa-solid fa-triangle-exclamation",
    DEFAULT_MESSAGE_ICON: "fa-solid fa-square-check",
    DEFAULT_ERROR_ICON: "fa-solid fa-circle-exclamation",
  };

  const Message = (data) => {
    messageDispatch({
      type: "ADD",
      payload: {
        content: {
          type: data.type ? data.type : Types.TYPE_MESSAGE,
          message: data.message,
          place: data.place ? data.place : Positions.POSITION_TOP_CENTER,
          icon: data.icon ? data.icon : Icons.DEFAULT_MESSAGE_ICON,
          autoDismiss: 2,
          closeButton: data.closeButton ? data.closeButton : true,
        },
      },
    });
  };
  const Warning = (data) => {
    messageDispatch({
      type: "ADD",
      payload: {
        content: {
          type: Types.TYPE_WARNING,
          message: data.message,
          place: data.place ? data.place : Positions.POSITION_TOP_CENTER,
          icon: data.icon ? data.icon : Icons.DEFAULT_WARNING_ICON,
          autoDismiss: data.autoDismiss ? data.autoDismiss : 5000,
          closeButton: data.closeButton ? data.closeButton : true,
        },
      },
    });
  };

  const Unauthorized = (data) => {
    messageDispatch({
      type: "ADD",
      payload: {
        content: {
          type: Types.TYPE_WARNING,
          message: data.message,
          place: data.place ? data.place : Positions.POSITION_TOP_CENTER,
          icon: data.icon ? data.icon : Icons.DEFAULT_WARNING_ICON,
          autoDismiss: data.autoDismiss ? data.autoDismiss : 5000,
          closeButton: data.closeButton ? data.closeButton : true,
        },
      },
    });
  };

  const Error = (data) => {
    messageDispatch({
      type: "ADD",
      payload: {
        content: {
          type: Types.TYPE_ERROR,
          message: data.message,
          place: data.place ? data.place : Positions.POSITION_TOP_CENTER,
          icon: data.icon ? data.icon : Icons.DEFAULT_ERROR_ICON,
          autoDismiss: data.autoDismiss ? data.autoDismiss : 5000,
          closeButton: data.closeButton ? data.closeButton : false,
        },
      },
    });
  };

  const cerrarAlertas = () => {
    messageDispatch({
      type: "CLOSE",
    });
  };

  return {
    Message,
    Warning,
    Error,
    Unauthorized,
    cerrarAlertas,
    Positions,
    Types,
  };
}
