import { UserService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const UserManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const buscarPorId = (Id) => {
    return UserService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const create = (user) => {
    return UserService.create(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (user) => {
    return UserService.actualizar(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return UserService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const cambiarContrasenia = (user) => {
    return UserService.cambiarContrasenia(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const borrar = (Id) => {
    return UserService.borrar(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    cambiarContrasenia,
    borrar,
  };
})();
