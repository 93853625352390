import { DashboardService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const DashboardManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

   const obtenerDatos = (where) => {
    where.SummerYear = window.localStorage.getItem('summerYear');
    return DashboardService.obtenerDatos(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    obtenerDatos,
  };
})();
