import { RequestHelper } from "../utils";

const RUTA = "SummerRecord";
export const SummerRecordService = (() => {
  const create = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/SummerRecord`, data);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(`${RUTA}/SummerRecord`, {
        Id: Id,
      });
    });
  };

  const borrar = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().deleteRequest(`${RUTA}/SummerRecord`, {
        Id: Id,
      });
    });
  };

  const buscarSummary = function (args) {
    return new Promise((res) => res(args)).then((guid) => {
      return RequestHelper().get(`StudentInformation/StudentInformation`, {
        recordGuid: guid,
      });
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      var a = RequestHelper().patch(`${RUTA}/SummerRecord`, data);

      return a;
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper().get(`${RUTA}/SummerRecords`, params);
    });
  };
  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    buscarSummary,
    borrar,
  };
})();
