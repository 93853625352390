import useMessage from "./useMessage";
import { ERROR_DEFAULT_MESSAGE } from "../utils";
export default function useExceptionHandler(e) {
const { Warning, Error } = useMessage();

  function handleException(error) {
    let message = error.warning ? error.message : ERROR_DEFAULT_MESSAGE;
    console.log(error);
    if (error.mostrarErrores) {
      message = `${message} ${error.exceptionType ?? ""} ${
        error.exceptionMessage ?? ""
      }  ${error.stackTrace ?? ""}`;
    }
    error.warning ? Warning({ message }) : Error({ message });
  }

  return { handleException };
}
