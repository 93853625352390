import { SummerApplicationService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const SummerApplicationManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const buscarPorId = (Id) => {
    return SummerApplicationService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const create = (user) => {
    return SummerApplicationService.create(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (summerApplication) => {
    return SummerApplicationService.actualizar(summerApplication).then(
      (res) => {
        if (!res.Success) {
          handleException(res);
        }
        return res.Data;
      }
    );
  };

  const listado = (where) => {
    where.SummerYear = window.localStorage.getItem('summerYear');
    return SummerApplicationService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const borrar = (Id) => {
    return SummerApplicationService.borrar(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    borrar,
  };
})();
