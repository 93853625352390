import { RequestHelper } from "../utils";

const RUTA = "Guarantor";
export const GuarantorService = (() => {
  const create = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/Guarantor`, data);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(`${RUTA}/Guarantor`, {
        Id: Id,
      });
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().patch(`${RUTA}/Guarantor`, data);
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper().get(`${RUTA}/Guarantors`, params);
    });
  };
  return {
    create,
    buscarPorId,
    actualizar,
    listado,
  };
})();
