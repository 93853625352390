import React, { Component, Suspense } from "react";
import SummaryRecord from "./views/SummaryRecord";
import "./scss/style.scss";
import "./custom.css";
// import Login from "./views/Login";

import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            exact
            path="/SummaryRecord"
            name="Summary"
            element={<SummaryRecord />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;

// function App() {
//
//   if (!user) {
//     return <Login></Login>;
//   } else {
//     return (
//       <Routes>
//         {AppRoutes.map((route, index) => {
//           const { element, ...rest } = route;
//           return <Route key={index} {...rest} element={element} />;
//         })}
//       </Routes>
//     );
//   }
// }

// export default App;
