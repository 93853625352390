import { API_URL, RequestHelper } from "../utils";
const RUTA = "Authentication";
export const AuthenticationService = (() => {
  const login = function (arg) {
    return new Promise((res) => res(arg))
      .then((data) => {
        return {
          NombreUsuario: data.email,
          Contrasena: data.password,
        };
      })
      .then((data) => {
        return RequestHelper().post(`${RUTA}/login`, data);
      });
  };
  const logout = () => {
    return RequestHelper().post(`${RUTA}/logout`, {});
  };

  const sendRecoveryEmail = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/sendRecoveryEmail`, data);
    });
  };

  const resetPassword = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/resetPassword`, data);
    });
  };

  const getCurrentSession = (sessionticket) => {
    return RequestHelper().post(`${RUTA}/session`, sessionticket);
  };

  return {
    login,
    logout,
    getCurrentSession,
    sendRecoveryEmail,
    resetPassword,
  };
})();
