import React, { useEffect } from "react";
import AlertNotification from "./AlertNotification";
import { useMessageContext } from "../../providers/message.provider";

function Notifications({ messageOptions }) {
  const notificationAlertRef = React.useRef(null);
  const { messageDispatch } = useMessageContext();
  const AUTO_DISMISS_SECONDS = 0;
  useEffect(() => {
    if (messageOptions.some((x) => x.type !== "CLOSE")) {
      notify(messageOptions[0].content);
    } else if (messageOptions.some((x) => x.type === "CLOSE")) {
      close();
    }
    return () => {
      messageDispatch({ type: "REMOVE_ALL" });
    };
  });
  function notify(content) {
    var options = {};
    options = {
      place: content.place,
      message: (
        <div>
          <div>{content.message}</div>
        </div>
      ),
      type: content.type,
      icon: content.icon,
      autoDismiss: content.autoDismiss
        ? content.autoDismiss
        : AUTO_DISMISS_SECONDS,
      closeButton: content.closeButton,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  function close() {
    notificationAlertRef.current.closeAll();
  }

  return (
    <>
      <div className="rna-container">
        <AlertNotification ref={notificationAlertRef} />
      </div>
    </>
  );
}

export default Notifications;
