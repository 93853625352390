import { InstallmentService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const InstallmentManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const buscarPorId = (Id) => {
    return InstallmentService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const create = (user) => {
    return InstallmentService.create(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (user) => {
    return InstallmentService.actualizar(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return InstallmentService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const pay = (id) => {
    return InstallmentService.pay(id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    pay,
  };
})();
