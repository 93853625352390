import { GuarantorService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const GuarantorManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const buscarPorId = (Id) => {
    return GuarantorService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const create = (user) => {
    return GuarantorService.create(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (user) => {
    return GuarantorService.actualizar(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    return GuarantorService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    create,
    buscarPorId,
    actualizar,
    listado,
  };
})();
