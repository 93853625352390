import { AuthenticationService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const AuthenticationManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const login = (usuario) => {
    return AuthenticationService.login(usuario).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const logout = () => {
    return AuthenticationService.logout().then((res) => {
      if (!res.Success) {
      }
    });
  };

  const sendRecoveryEmail = (params) => {
    return AuthenticationService.sendRecoveryEmail(params).then((res) => {
      if (!res.Success) {
      }
      return res.Data;
    });
  };

  const resetPassword = (params) => {
    return AuthenticationService.resetPassword(params).then((res) => {
      if (!res.Success) {
      }
      return res.Data;
    });
  };

  const getSession = (sessionId) => {
    return AuthenticationService.getCurrentSession(sessionId);
  };

  return {
    login,
    logout,
    sendRecoveryEmail,
    resetPassword,
    getSession,
  };
})();
