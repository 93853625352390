import React, { useState, useEffect } from "react";
import { FormHelper } from "../utils";
import useMessage from "../hooks/useMessage";
import useExceptionHandler from "../hooks/useExceptionHandler";
import { UserManager } from "../managers";
import useQueryParams from "../hooks/useQueryParams";
import { SummerRecordManager } from "../managers";
import { DateHelper } from "../utils";
import Loader from "../components/Loader/Loader";
import CambiarContrasenia from "../components/CambiarContrasenia";

import {
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CRow,
} from "@coreui/react";

function SummaryRecord() {
  const [user, setUser] = useState({ status: "A", Password: "123456" });
  const [esNuevo, setEsNuevo] = useState(false);
  const { Message, cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const URLParameters = useQueryParams();
  const recordGuid = URLParameters.get("RecordGuid");
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    getSummary(recordGuid);

    return () => {
      cerrarAlertas();
    };
  }, []);

  const getSummary = (Id) => {
    setIsLoading(true);
    SummerRecordManager.buscarSummary(Id)
      .then((data) => {
        setUser(data);
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputChange = (e) => {
    FormHelper.handleChange(e, setUser);
  };

  return (
    <>
      <Loader show={isLoading} elementBlur="userForm" />
      <div id="userForm" style={{ padding: "100px" }}>
        <CForm
          noValidate
          validated={validated}
          className="row g-3 needs-validation mb-5"
        >
          <h5 className="mb-3 mt-5">Información del Expediente</h5>
          <CRow className="mb-3">
            <CCol sm={6}>
              <CFormLabel htmlFor="status" className="col-form-label">
                Estatus
              </CFormLabel>
              <CFormInput
                type="text"
                id="status"
                value={user.status}
                name="status"
                onChange={onInputChange}
                disabled={true}
              />
            </CCol>
          </CRow>
          <h5 className="mb-3 mt-5">Información del Estudiante</h5>
          <CRow className="mb-3">
            <CCol sm={6}>
              <CFormLabel
                htmlFor="FirstName"
                className="col-sm-2 col-form-label"
              >
                Nombres
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="text"
                  id="FirstName"
                  value={user.firstName}
                  name="firstName"
                  onChange={onInputChange}
                  aria-describedby="FirstNameFeedback"
                  disabled={true}
                />
              </CInputGroup>
            </CCol>
            <CCol sm={6}>
              <CFormLabel
                htmlFor="LastName"
                className="col-sm-2 col-form-label"
              >
                Apellidos
              </CFormLabel>
              <CFormInput
                type="text"
                id="LastName"
                value={user.lastName}
                name="lastName"
                onChange={onInputChange}
                aria-describedby="LastNameFeedback"
                disabled={true}
              />
            </CCol>

            <CCol sm={6}>
              <CFormLabel htmlFor="Email" className="col-sm-2 col-form-label">
                Email
              </CFormLabel>
              <CFormInput
                type="email"
                id="Email"
                value={user.email}
                name="email"
                onChange={onInputChange}
                aria-describedby="EmailFeedback"
                disabled={true}
              />
            </CCol>

            <CCol sm={6}>
              <CFormLabel htmlFor="NationalId" className=" col-form-label">
                Documento de Identidad
              </CFormLabel>
              <CFormInput
                type="text"
                id="NationalId"
                value={user.nationalId}
                name="nationalId"
                onChange={onInputChange}
                disabled={true}
              />
            </CCol>
            <CCol sm={6}>
              <CFormLabel htmlFor="DateOfBirth" className="col-form-label">
                Fecha de Nacimiento
              </CFormLabel>
              <CFormInput
                type="date"
                id="DateOfBirth"
                value={DateHelper.formatDate(user.dateOfBirth)}
                name="dateOfBirth"
                onChange={onInputChange}
                disabled={true}
              />
            </CCol>
          </CRow>

          <h5 className="mb-3 mt-5">Información del Empleador</h5>
          <CRow className="mb-3">
            <CCol sm={6}>
              <CFormLabel
                htmlFor="employerName"
                className="col-sm-2 col-form-label"
              >
                Nombres
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="text"
                  id="employerName"
                  value={user.employerName}
                  name="employerName"
                  onChange={onInputChange}
                  aria-describedby="FirstNameFeedback"
                  disabled={true}
                />
              </CInputGroup>
            </CCol>
            <CCol sm={6}>
              <CFormLabel
                htmlFor="employerState"
                className="col-sm-2 col-form-label"
              >
                Estado
              </CFormLabel>
              <CFormInput
                type="text"
                id="employerState"
                value={user.employerState}
                name="employerState"
                onChange={onInputChange}
                aria-describedby="LastNameFeedback"
                disabled={true}
              />
            </CCol>

            <CCol sm={6}>
              <CFormLabel
                htmlFor="employerAddress"
                className="col-sm-2 col-form-label"
              >
                Dirección
              </CFormLabel>
              <CFormInput
                type="employerAddress"
                id="employerAddress"
                value={user.employerAddress}
                name="email"
                onChange={onInputChange}
                aria-describedby="EmailFeedback"
                disabled={true}
              />
            </CCol>
          </CRow>
        </CForm>
      </div>
    </>
  );
}

export default SummaryRecord;
