import { RequestHelper } from "../utils";

const RUTA = "MainPanel";
export const DashboardService = (() => {
  const obtenerDatos = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper().get(`${RUTA}/Dashboard`, params);
    });
  };
  return {
    obtenerDatos,
  };
})();
