import React, { useState, useEffect, useCallback } from "react";
import { AuthenticationManager } from "../managers/authentication.manager";
import { AuthenticationContext } from "../contexts";
import { SESSION_KEY } from "../utils/constants";

export function AuthenticationProvider({ children }) {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    var storedSession = getCurrentSessionLocal();
    if (storedSession) {
      getCurrentSessionServer(storedSession);
    }
  }, []);

  const getCurrentSessionLocal = () => {
    return localStorage.getItem(SESSION_KEY);
  };

  const getCurrentSessionServer = (sessionId) => {
    return AuthenticationManager.getSession(sessionId).then((res) => {
      setUser(res.Data);
    });
  };

  const setCurrentSession = useCallback(
    (data) => {
      localStorage.setItem(SESSION_KEY, data?.ticketId);

      setUser(data);
    },
    [user]
  );

  const login = (authModel) => {
    return AuthenticationManager.login(authModel).then((data) => {
      setCurrentSession(data);
    });
  };

  function logout() {
    return AuthenticationManager.logout().then(() => {
      setCurrentSession(null);
    });
  }

  return (
    <AuthenticationContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthenticationContext.Provider>
  );
}
