import { CModal, CModalBody } from "@coreui/react";
import React, { useEffect } from "react";
import { CModalHeader } from "@coreui/react";
import { CModalDialog, CModalTitle } from "@coreui/react";
import { CButton } from "@coreui/react";
import { CModalFooter } from "@coreui/react";
import { FormHelper } from "../utils";
import { CRow, CCol, CFormLabel, CInputGroup, CFormInput } from "@coreui/react";
import { useState } from "react";
import { UserManager } from "../managers";
import useExceptionHandler from "../hooks/useExceptionHandler";
import useMessage from "../hooks/useMessage";
import useQueryParams from "../hooks/useQueryParams";

function CambiarContrasenia({ visible, setVisible, userId }) {
  const URLParameters = useQueryParams();
  const Id = URLParameters.get("Id");
  const [cambioContrasenia, setCambioContrasenia] = useState({
    userId: Id,
  });
  const { handleException } = useExceptionHandler();
  const { Message, cerrarAlertas } = useMessage();
  const onInputChange = (e) => {
    FormHelper.handleChange(e, setCambioContrasenia);
  };

  const CambiarContrasenia = () => {
    UserManager.cambiarContrasenia(cambioContrasenia)
      .then((data) => {
        Message({ message: `Contraseña Cambiada Exitosamente` });
      })
      .catch((e) => {
        handleException(e);
      })
      .finally(() => {});
  };

  return (
    <>
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle>Cambiar Contraseña</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow className="mb-3">
            <CCol sm={12}>
              <CFormLabel
                htmlFor="contraseniaNueva"
                className="col-sm-6 col-form-label"
              >
                Nueva Contraseña
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="password"
                  id="contraseniaNueva"
                  value={cambioContrasenia.contraseniaNueva}
                  name="contraseniaNueva"
                  onChange={onInputChange}
                  aria-describedby="contraseniaNuevaFeedback"
                  feedbackInvalid="Campo Contraseña Nueva es Requerido"
                  required
                />
              </CInputGroup>
            </CCol>
            <CCol sm={12}>
              <CFormLabel
                htmlFor="contraseniaNuevaConfirm"
                className="col-sm-6 col-form-label"
              >
                Confirmar Contraseña
              </CFormLabel>
              <CFormInput
                type="password"
                id="contraseniaNuevaConfirm"
                value={cambioContrasenia.contraseniaNuevaConfirm}
                name="contraseniaNuevaConfirm"
                onChange={onInputChange}
                aria-describedby="LastNameFeedback"
                feedbackInvalid="Campo Confirmar Contraseña es Requerido"
                required
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cerrar
          </CButton>
          <CButton color="primary" onClick={CambiarContrasenia}>
            Cambiar Contraseña
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default CambiarContrasenia;
