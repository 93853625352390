import { RequestHelper } from "../utils";

const RUTA = "SummerApplication";
export const SummerApplicationService = (() => {
  const create = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/SummerApplication`, data);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(`${RUTA}/SummerApplication`, {
        Id: Id,
      });
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      var a = RequestHelper().patch(`${RUTA}/SummerApplication`, data);

      return a;
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper().get(`${RUTA}/SummerApplications`, params);
    });
  };

  const borrar = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().deleteRequest(`${RUTA}/SummerApplication`, {
        Id: Id,
      });
    });
  };
  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    borrar,
  };
})();
