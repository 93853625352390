import { SummerRecordService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";

export const SummerRecordManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const buscarPorId = (Id) => {
    return SummerRecordService.buscarPorId(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const borrar = (Id) => {
    return SummerRecordService.borrar(Id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const buscarSummary = (guid) => {
    return SummerRecordService.buscarSummary(guid).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const create = (user) => {
    return SummerRecordService.create(user).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const actualizar = (summerApplication) => {
    return SummerRecordService.actualizar(summerApplication).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };

  const listado = (where) => {
    where.SummerYear = window.localStorage.getItem('summerYear');
    return SummerRecordService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    buscarSummary,
    borrar,
  };
})();
