import { SESSION_KEY } from "./constants";
import { ResponseHeadersHelper } from "./response.header.helper";
import { UnauthorizedException } from "./exceptions";

export const RequestHelper = (rootUrl) =>
  ((rootUrl) => {
    const getUserToken = () => {
      return localStorage.getItem(SESSION_KEY);
    };

    const getDefaultHeaders = () => {
      return {
        SessionId: getUserToken(),
        "Content-Type": "application/json",
      };
    };

    const getEndpoint = (apiPath, params) => {
      let url = apiPath;
      if (params) {
        url += "?" + serializeParams(params);
      }
      return url;
    };

    const serializeParams = (obj) => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };

    const handleResponse = (response, isBlob = false) => {
      const headersHelper = ResponseHeadersHelper(response.headers);

      if (isBlob) {
        return response.blob();
      }
      return response.json().then(
        (data) =>
          new Promise((res) =>
            res({
              Success: response.ok,
              StatusCode: response.status,
              Data: data,
              Headers: headersHelper.getPaginationHeaders(),
            })
          )
      );
    };

    const get = (path, params, isBlob = false) => {
      return fetch(getEndpoint(path, params), {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),

        method: "get",
        mode: "cors",
      }).then((res) => handleResponse(res, isBlob));
    };

    const deleteRequest = (path, params, isBlob = false) => {
      return fetch(getEndpoint(path, params), {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),

        method: "delete",
        mode: "cors",
      }).then((res) => handleResponse(res, isBlob));
    };

    const post = (path, data) => {
      return fetch(path, {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),
        credentials: "include",
        body: JSON.stringify(data),
        method: "post",
      }).then((res) => handleResponse(res));
    };

    const patch = (path, data) => {
      return fetch(path, {
        headers: new Headers({
          ...getDefaultHeaders(),
        }),
        credentials: "include",
        body: JSON.stringify(data),
        method: "PATCH",
      }).then((res) => handleResponse(res));
    };

    const postWithFiles = (apiPath, data) => {
      const formData = new FormData();
      formData.append("json", JSON.stringify(data));
      data.Files.map((file, i) => {
        return formData.append(`File-${i}`, file);
      });
      return fetch(getEndpoint(apiPath), {
        body: formData,
        method: "post",
        mode: "cors",
        headers: new Headers({
          SessionId: getUserToken(),
        }),
      }).then((res) => handleResponse(res));
    };

    return {
      post,
      get,
      postWithFiles,
      patch,
      deleteRequest,
    };
  })(rootUrl);
