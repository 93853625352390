import { RequestHelper } from "../utils";
const RUTA = "Attachment";
export const ArchivoService = (() => {
  const grabar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().postWithFiles(`${RUTA}/grabar`, data);
    });
  };
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      console.log(args);
      return RequestHelper().get(`${RUTA}/listar`, params);
    });
  };

  const descargarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(
        `${RUTA}/descargarPorId`,
        {
          Id: Id,
        },
        true
      );
    });
  };

  const borrarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(`${RUTA}/borrarPorId`, {
        Id: Id,
      });
    });
  };

  return {
    grabar,
    listado,
    descargarPorId,
    borrarPorId,
  };
})();
