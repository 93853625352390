import { RequestHelper } from "../utils";

const RUTA = "Role";
export const RoleService = (() => {
  const create = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/Role`, data);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(`${RUTA}/Role`, {
        Id: Id,
      });
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().patch(`${RUTA}/Role`, data);
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper().get(`${RUTA}/Roles`, params);
    });
  };
  return {
    create,
    buscarPorId,
    actualizar,
    listado,
  };
})();
