export const API_URL = process.env.REACT_APP_API_URL;
export const SESSION_KEY = "SessionId";

export const ERROR_DEFAULT_MESSAGE =
  process.env.REACT_APP_MENSAJE_ERROR_DEFECTO;

export const VALIDATION_ERROR_HTTP_CODE = 400;
export const UNAUTHORIZED_ERROR_HTTP_CODE = 401;

export const HEADER_WHERE_INDICE_PAGINA = "x-where-pageindex";
export const HEADER_WHERE_CANTIDAD_PAGINA = "x-where-pagesize";
export const HEADER_WHERE_RECORDS_TOTAL = "x-where-recordstotal";
export const HEADER_SESSION_TOKEN = "x-session-token";

export const COLOR_PRINCIPAL = "#002c7c";

export const DEFAULT_PAGINATION_VALUES = {
  EsPaginable: true,
  CantidadPorPagina: 10,
  EsOrdernable: true,
  OrderBy: "Id",
};
