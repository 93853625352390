import { RequestHelper } from "../utils";

const RUTA = "User";
export const UserService = (() => {
  const create = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/User`, data);
    });
  };

  const buscarPorId = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().get(`${RUTA}/User`, {
        Id: Id,
      });
    });
  };

  const actualizar = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().patch(`${RUTA}/User`, data);
    });
  };

  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper().get(`${RUTA}/Users`, params);
    });
  };

  const cambiarContrasenia = function (args) {
    return new Promise((res) => res(args)).then((data) => {
      return RequestHelper().post(`${RUTA}/CambiarContrasenia`, data);
    });
  };

  const borrar = function (args) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper().deleteRequest(`${RUTA}/User`, {
        Id: Id,
      });
    });
  };

  return {
    create,
    buscarPorId,
    actualizar,
    listado,
    cambiarContrasenia,
    borrar,
  };
})();
