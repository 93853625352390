import { ArchivoService } from "../services";
import { ResponseExceptionHandlerHelper } from "../utils";
export const ArchivoManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();
  const grabar = (archivos) => {
    return ArchivoService.grabar(archivos).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res.Data;
    });
  };
  const listado = (where) => {
    return ArchivoService.listado(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  const descargarPorId = (id) => {
    return ArchivoService.descargarPorId(id).then((res) => {
      return res;
    });
  };

  const borrarPorId = (id) => {
    return ArchivoService.borrarPorId(id).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    grabar,
    listado,
    descargarPorId,
    borrarPorId,
  };
})();
