import {
  VALIDATION_ERROR_HTTP_CODE,
  UNAUTHORIZED_ERROR_HTTP_CODE,
  WarningException,
  ErrorException,
  ConfirmException,
  UnauthorizedException,
} from "../utils";

export const ResponseExceptionHandlerHelper = () =>
  (() => {
    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    const handleException = (response) => {
      var isJson = isJsonString(response.Data.message);
      if (response.StatusCode == VALIDATION_ERROR_HTTP_CODE && !isJson) {
        throw new WarningException(response.Data.message);
      } else if (response.StatusCode == VALIDATION_ERROR_HTTP_CODE && isJson) {
        throw new ConfirmException(response.Data.message);
      } else if (response.StatusCode == UNAUTHORIZED_ERROR_HTTP_CODE) {
        throw new UnauthorizedException(response.Data.message);
      } else {
        throw new ErrorException(
          response.Data.message,
          response.Data.ExceptionType,
          response.Data.ExceptionMessage,
          response.Data.StackTrace
        );
      }
    };

    return {
      handleException,
    };
  })();
