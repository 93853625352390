import React, { useEffect, useState } from "react";
import { CSpinner } from "@coreui/react";
function Loader({ show, elementBlur }) {
  if (!show) {
    var element = document.getElementById(elementBlur);
    if (element != null) {
      element.style.filter = "blur(0px)";
    }
    return <></>;
  } else {
    var element = document.getElementById(elementBlur);

    if (element != null) {
      element.style.filter = "blur(3px)";
    }
  }
  return (
    <div
      style={{
        position: "absolute",
        width: "80%",
        height: "80%",
        zIndex: "9999999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CSpinner size="lg" color="primary" aria-hidden="true"></CSpinner>
    </div>
  );
}

export default Loader;
